/* reset */
html {
  box-sizing: border-box;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

a:active {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: inherit;
  text-decoration: none;
}

/* colors */
:root {
  --secondary: rgb(30, 144, 255);
  --primary: rgb(214, 214, 214);
  --light: rgb(255, 255, 255);
  --dark: hsl(185, 53%, 45%);
  --green: rgb(10, 135, 49);
  --gray: hsl(0deg 0% 65% / 85%);
  --shadow: 0 3px 5px 2px rgba(95, 95, 95, 0.35);
}

/* basic */
.background-box {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url(../public/assets/background.jpg);
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 95vh;
  width: 90vw;
}

/* layout-elements */
.date-time {
  width: 100%;
  text-align: right;
  font-size: .8rem;
}

.main-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.logo-animation {
  display: grid;
}

.logo-animation img {
  margin: auto;
}

.bottom-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 4.5rem;
  font-size: .8rem;
}

.bottom-links {
  display: flex;
}

.bottom-links svg {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
}

.menu-icon {
  position: absolute;
  bottom: 0;
  left: 50%;
  translate: -50%;
  background-color: var(--light);
  border: 1px solid var(--primary);
  border-radius: 50%;
  width: 4.5rem;
  height: 4.5rem;
  padding: 1rem;
  cursor: pointer;
}

.menu-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-switch {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.menu-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 45vh;
  width: 70vw;
  padding: 0 2rem;
  background-color: var(--gray);
  backdrop-filter: blur(6px);
  border-radius: 2rem;
  box-shadow: var(--shadow);
  z-index: 10;
}

.menu-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-item svg {
  height: 5rem;
  width: 5rem;
}

.menu-item svg {
  height: 5rem;
  width: 5rem;
}

.foundation-ico path {
  fill: var(--primary);
}

.menu-item h3 {
  margin-top: 2rem;
  text-align: center;
}

.heksagon {
  width: 100%;
  height: 100%;
  background-image: url(../public/assets/heksagon.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  color: var(--green);
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* typography */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  color: var(--primary);
  font-family: 'Montserrat', sans-serif;
}

button {
  background: none;
  border: none;
  width: 100%;
  padding: 0;
}

/* button */
.button {
  background-color: var(--gray);
  backdrop-filter: blur(6px);
  border-radius: 2rem;
  box-shadow: var(--shadow);
  font-size: 1rem;
  font-weight: 600;
  width: 85%;
  text-align: center;
  padding: 0.5rem 0.5rem;
  transition: all .4s ease;
}

.button:hover {
  background-color: var(--dark);
  font-weight: 700;
  width: 100%;
  margin: .8em 0;
  transition: all .4s ease;
}

/* slider */
.slider-holder {
  position: relative;
  width: 55vw;
  height: 65vh;
}

.slider-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* start */
.start-page {
  max-width: 50%;
}

.start-page > .slider-holder {
  z-index: 0;
  margin-left: -15%;
  margin-top: -15%;
}

.start-content {
  max-width: 50%;
  z-index: 1;
}

.content-box {
  padding: 1rem 1rem;
  background-color: var(--gray);
  backdrop-filter: blur(6px);
  border-radius: 2rem;
  box-shadow: var(--shadow);
}

.content-box h2, p {
  margin-bottom: 1em;
}

.content-box h2 {
  font-size: 2rem;
  font-weight: 600;
}

.content-box p {
  font-size: 1.1rem;
  line-height: 1.4rem;
}

/* council */
.person-card {
  max-width: 50%;
  max-height: 75vh;
  padding: 3rem;
  overflow: hidden;
}

.person-heading {
  display: flex;
  align-items: center;
}

.person-desc {
  overflow-y: scroll;
  max-height: 40vh;
}

.person-desc::-webkit-scrollbar {
  width: 1rem;
}

.person-heading h2 {
  margin-bottom: 0;
}

.person-img {
  max-width: 8rem;
  margin-right: 2rem;
}

.council-menu {
  width: 33%;
  margin-right: 2rem;
}

.council-menu h1 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: .7em;
}

.button-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 55vh;
  overflow-y: scroll;
  overflow-x: visible;
}

.council-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 4rem;
}

.council-button-active .button {
  background-color: var(--dark);
  font-weight: 700;
  width: 100%;
  margin: .8em 0;
  transition: all .4s ease;
}

.council-menu-offset {
  min-height: 45rem;
}

.council-menu svg {
  max-height: 1.2rem;
  max-width: 1.2rem;
  min-height: 1.2rem;
  min-width: 1.2rem;
}

.button-list::-webkit-scrollbar {
  width: 1rem;
}

/* about */
.about-menu {
  position: relative;
}

.about-icon {
  max-width: 11rem;
  max-height: 11rem;
  background-color: var(--light);
  border: 1px solid var(--green);
  border-radius: 50%;
  padding: 2rem;
}

.about-icon img {
  position: relative;
  z-index: 2;
}

.about-item-top {
  height: 6rem;
  display: flex;
  align-items: flex-start;
}

.about-item-bottom {
  height: 6rem;
  display: flex;
  align-items: flex-end;
}

.h-line-top {
  width: 2px;
  height: 6rem;
  border-left: 2px solid var(--light);
  transform: scaleX(1);
  transform-origin: top left;
  transition: all .4s ease;
}

.h-line-bottom {
  width: 2px;
  height: 6rem;
  border-left: 2px solid var(--light);
  transform: scaleX(1);
  transform-origin: top left;
  transition: all .4s ease;
}

.v-line-top {
  height: 6rem;
  border-top: 2px solid var(--light);
}

.v-line-top:hover {
  height: 6rem;
  border-top: 2px solid var(--light);
  transform-origin: top left;
  transition: all .4s ease;
}

.v-line-bottom {
  height: 6rem;
  border-bottom: 2px solid var(--light);
}

.v-line-bottom:hover {
  height: 6rem;
  border-bottom: 2px solid var(--light);
  transform-origin: top left;
  transition: all .4s ease;
}

.v-line-open {
  width: 5vw;
}

.v-line-closed {
  width: 50vw;
}

.about-button-top {
  width: 20rem;
  transform: translateY(-50%);
}

.about-button-bottom {
  width: 20rem;
  transform: translateY(50%);
}

.about-item-1 {
  margin-left: 3rem;
}

.about-item-1 .h-line-top {
  height: 15rem;
}

.about-item-2 {
  margin-left: 7rem;
}

.about-item-2 .h-line-top {
  height: 10rem;
}

.about-item-3 {
  margin-left: 7rem;
}

.about-item-3 .h-line-bottom {
  height: 10rem;
}

.about-item-4 {
  margin-left: 3rem;
}

.about-item-4 .h-line-bottom {
  height: 15rem;
}

.about-menu .button {
  font-size: 1.4rem;
  font-weight: 700;
}

.about-menu .button:hover {
  font-size: 2.2rem;
  font-weight: 700;
}

.about-content {
  max-height: 55vh;
  margin-left: 2.2rem;
}

.about-content .content-box {
  max-height: 55vh;
  overflow: hidden;
  padding: 1.6rem;
}

.about-content .content-desc {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 50vh;
  font-size: 1.3rem;
}

.about-active .button {
  background-color: var(--dark);
  font-size: 1.5rem;
  font-weight: 700;
  width: 100%;
  margin: .8em 0;
  transition: all .4s ease;
}

.about-content .content-desc::-webkit-scrollbar {
  width: 1rem;
}

.about {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 80%;
}

.about-desc > ol {
  list-style-type: decimal;
  font-size: 1.1rem;
  line-height: 1.4rem;
}

.about-desc > ol > li {
  margin-bottom: 10px;
}

/* bottom links */
.bottom-links-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 50vh;
  position: fixed;
  bottom: 14vh;
  left: 3vw;
  padding: 1rem 3rem;
  background-color: var(--gray);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  border-radius: 2rem;
  box-shadow: var(--shadow);
  z-index: 10;
}

.bottom-links-overlay .background {
  position: absolute;
  top: -50vh;
  left: -3vw;
  width: 100vw;
  height: 100vh;
  z-index: 2;
}

.bottom-links-overlay h2 {
  font-size: 1.3rem;
  font-weight: 700;
}

.bottom-links-overlay img {
  margin: 1rem auto;
}

.bottom-links svg {
  z-index: 10;
}

/* projects */

.project-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 65vh;
  padding: 0 2rem;
  background-color: var(--gray);
  backdrop-filter: blur(6px);
  border-radius: 2rem;
  box-shadow: var(--shadow);
  z-index: 10;
  scale: .8;
  transition: all .3s ease-in-out;
}

.swiper-slide-active > .project-card {
  scale: 1;
  transition: all .3s ease-in-out;
}

.project-main {
  display: flex;
}

.project-body {
  font-size: 1.4rem;
  line-height: 1.5rem;
  width: 70%;
}

.project-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}

.project-side img {
  margin-bottom: 2rem;
}

.project-title {
  font-size: 1.7rem;
  font-weight: 800;
  text-align: center;
}

.project-list {
  font-size: 1.5rem;
  font-weight: 600;
  list-style: circle;
}

.projects-list {
  display: flex;
}

.swiper {
  overflow: visible !important;
  width: 90%;
  height: 100%;
}

/* history */

.heksagon-history {
  width: 8rem;
  height: 8rem;
  font-size: 1.5rem;
  transition: all .3s ease;
}

.swiper-slide-active > .heksagon-history {
  width: 11rem;
  height: 11rem;
  font-size: 2rem;
  transition: all .3s ease;
}

.timeline {
  height: 11rem;
  align-items: center;
}

.timeline > .swiper-wrapper > .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline-wrapper {
  position: relative;
}

.timeline-wrapper > .swiper {
  overflow: hidden !important;
}

.timeline-line-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  height: 11rem;
  width: 100%;
}

.timeline-line {
  width: 100%;
  height: 3px;
  background-color: var(--light);
}

.history {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.history-card {
  max-width: 60%;
  max-height: 75vh;
  padding: 3rem;
  overflow: hidden;
  flex-grow: 2;
}

.history-top-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.history-top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
  width: 70%;
}

.history-title {
  text-align: left;
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 0.7em;
  flex-grow: 1;
  width: 70%;
  padding-left: 6rem;
}

/* gallery */

.gallery {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-height: 78vh;
  gap: 15px;
}

.gallery::-webkit-scrollbar {
  width: 1rem;
}

.gallery-thumb {
  flex-basis: 1;
  max-width: 30%;
}

.gallery-grid {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.modal-overlay {
  position: absolute;
  overflow: visible;
  background-color: #03495e8f;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
}

.lightbox-img {
  max-width: 75%;
}

.modal-navigation {
  display: flex;
  gap: 150px;
  margin-top: 30px;
}

/* map */
.map {
  display: flex;
  width: 90%;
  align-items: stretch;
}

.map-box {
  width: 100%;
  padding: 2.2rem 3.3rem;
}

.map-legend {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100%;
  flex-basis: 35%;
  margin-right: 3rem;
}

.map-legend-list {
  padding: 2.2rem;
}

.map-legend-list-item {
  margin-bottom: .6rem;
}

.map-legend-list > ul > li.active {
  color:#03495e;
}

.map-legend-list-item span {
  font-size: 1.1rem;
  font-weight: 800;
  margin-right: 1rem;
}

.map-title {
  font-size: 2.5rem;
  font-weight: 800;
  text-align: center;
}

/* map svg classes */
.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#BCBCBC;}
.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#D9D9D9;stroke:#898989;stroke-width:0.3066;stroke-miterlimit:2.4529;}
.st3{fill:#898989;}
.st4{fill-rule:evenodd;clip-rule:evenodd;fill:#07A3B2;stroke:#FFFFFF;stroke-width:0.6132;stroke-miterlimit:2.4529;}
.st5{fill:#07A3B2;stroke:#FFFFFF;stroke-width:1.2265;stroke-miterlimit:2.4529;}
.st6{fill-rule:evenodd;clip-rule:evenodd;fill:#FF7A00;}
.st7{fill:#FF7A00;}
.st8{fill-rule:evenodd;clip-rule:evenodd;fill:#07A3B2;}
.st9{fill:#FFFFFF;}
.st10{display:none;}
.st11{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#D9D9D9;stroke:#898989;stroke-width:0.3066;stroke-miterlimit:2.4529;}
.st12{display:inline;fill:#898989;}
.st13{display:inline;}